*,::after,::before {
    box-sizing: border-box
}
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none;
    outline: 0;
    //position: relative;
}
body {
    /*font-family: 'Roboto', sans-serif;*/
    font-size: 12px;
    font-family: 'Jost', sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: 0;
    touch-action: manipulation;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" 0;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input {
    font-family: 'Jost', sans-serif;
}
ul, ol, li {
    padding: 0;
    margin: 0;
    list-style: none;
}
p {
    margin: 0;
}
a {
    text-decoration: none;
    cursor: pointer;
}
img {
    width: 100%;
}
.p-0, .padding-0 {
    padding: 0!important
}
.m-0, .margin-0 {
    margin: 0!important
}
.d-none,.hidden, .hide {
    display: none!important
}
button {
    cursor: pointer;
    outline: 0;
    border: 0;
    background: none;
}
.d-flex,.flex {
    display: -moz-flex;
    display: -webkit-flex
}
.d-inline-flex {
    display: inline-flex!important
}
.h-flex,.v-flex {
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-self: stretch
}
.h-flex {
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center
}
.v-flex {
    flex: 1 1 auto;
    -ms-flex-direction: column;
    flex-direction: column;
}
.flex-row {
    -ms-flex-direction: row!important;
    flex-direction: row!important
}
.flex-column, .flex.column {
    -ms-flex-direction: column!important;
    flex-direction: column!important
}
.flex-row-reverse {
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}
.flex-column-reverse {
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}
.flex-wrap {
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
}
.flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
}
.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
}
.flex-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important
}
.flex-grow-0 {
    -ms-flex-positive: 0!important;
    flex-grow: 0!important
}
.flex-grow-1 {
    -ms-flex-positive: 1!important;
    flex-grow: 1!important
}
.flex-shrink-0 {
    -ms-flex-negative: 0!important;
    flex-shrink: 0!important
}
.flex-shrink-1 {
    -ms-flex-negative: 1!important;
    flex-shrink: 1!important
}
.justify-content-start {
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}
.justify-content-end {
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}
.justify-content-center,.allign-center,.position-center {
    -ms-flex-pack: center!important;
    justify-content: center!important
}
.justify-content-between {
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}
.justify-content-around {
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}
.align-items-start {
    -ms-flex-align: start!important;
    align-items: flex-start!important
}
.align-items-end {
    -ms-flex-align: end!important;
    align-items: flex-end!important
}
.align-items-center,.allign-middle,.position-center {
    -ms-flex-align: center!important;
    align-items: center!important
}
.align-items-baseline {
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}
.align-items-stretch {
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}
.align-content-start {
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
}
.align-content-end {
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
}
.align-content-center {
    -ms-flex-line-pack: center!important;
    align-content: center!important
}
.align-content-between {
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
}
.align-content-around {
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
}
.align-content-stretch {
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
}
.align-self-auto {
    -ms-flex-item-align: auto!important;
    align-self: auto!important
}
.align-self-start {
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
}
.align-self-end {
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
}
.align-self-center {
    -ms-flex-item-align: center!important;
    align-self: center!important
}
.align-self-baseline {
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
}
.align-self-stretch {
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
}
.align-baseline {
    vertical-align: baseline!important
}
.align-top {
    vertical-align: top!important
}
.align-middle {
    vertical-align: middle!important
}
.align-bottom {
    vertical-align: bottom!important
}
.align-text-bottom {
    vertical-align: text-bottom!important
}
.align-text-top {
    vertical-align: text-top!important
}
.float-start {
    float: left!important
}
.float-end {
    float: right!important
}
.float-none {
    float: none!important
}
.opacity-0 {
    opacity: 0!important
}
.opacity-25 {
    opacity: .25!important
}
.opacity-50 {
    opacity: .5!important
}
.opacity-75 {
    opacity: .75!important
}
.opacity-100 {
    opacity: 1!important
}
.overflow-auto {
    overflow: auto!important
}
.overflow-hidden {
    overflow: hidden!important
}
.overflow-visible {
    overflow: visible!important
}
.overflow-scroll {
    overflow: scroll!important
}
.d-inline {
    display: inline!important
}
.d-inline-block {
    display: inline-block!important
}
.d-block {
    display: block!important
}
.d-grid {
    display: grid!important
}
.d-table {
    display: table!important
}
.d-table-row {
    display: table-row!important
}
.d-table-cell {
    display: table-cell!important
}


.position-static {
    position: static!important
}
.position-relative {
    position: relative!important
}
.position-absolute {
    position: absolute!important
}
.position-fixed {
    position: fixed!important
}
.position-sticky {
    position: -webkit-sticky!important;
    position: sticky!important
}
.top-0 {
    top: 0!important
}
.top-50 {
    top: 50%!important
}
.top-100 {
    top: 100%!important
}
.bottom-0 {
    bottom: 0!important
}
.bottom-50 {
    bottom: 50%!important
}
.bottom-100 {
    bottom: 100%!important
}
.start-0 {
    left: 0!important
}
.start-50 {
    left: 50%!important
}
.start-100 {
    left: 100%!important
}
.end-0 {
    right: 0!important
}
.end-50 {
    right: 50%!important
}
.end-100 {
    right: 100%!important
}
.translate-middle {
    transform: translate(-50%,-50%)!important
}
.translate-middle-x {
    transform: translateX(-50%)!important
}
.translate-middle-y {
    transform: translateY(-50%)!important
}
.border-0 {
    border: 0!important
}
.w-0 {
    width: 0!important
}
.w-1 {
    width: .25rem!important
}
.w-2 {
    width: .5rem!important
}
.w-3 {
    width: 1rem!important
}
.w-4 {
    width: 2rem!important
}
.w-5 {
    width: 5%!important
}
.w-15 {
    width: 15%!important
}
.w-25 {
    width: 25%!important
}
.w-35 {
    width: 35%!important
}
.w-45 {
    width: 45%!important
}
.w-55 {
    width: 55%!important
}
.w-65 {
    width: 65%!important
}
.w-75 {
    width: 75%!important
}
.w-85 {
    width: 85%!important
}
.w-95 {
    width: 95%!important
}
.w-50 {
    width: 50%!important
}
.w-75 {
    width: 75%!important
}
.w-90 {
    width: 90%!important
}
.w-80 {
    width: 80%!important
}
.w-70 {
    width: 70%!important
}
.w-60 {
    width: 60%!important
}
.w-40 {
    width: 40%!important
}
.w-30 {
    width: 30%!important
}
.w-20 {
    width: 20%!important
}
.w-10 {
    width: 10%!important
}
.w-100 {
    width: 100%!important
}
.w-auto {
    width: auto!important
}
.h-100 {
    height: 100%!important
}
.h-auto {
    height: auto !important;
  }
.mw-100 {
    max-width: 100%!important
}
.vw-100 {
    width: 100vw!important
}
.min-vw-100 {
    min-width: 100vw!important
}
.order-last {
    order: 6!important
}
.m-1 {
    margin: .25rem!important
}
.m-2 {
    margin: .5rem!important
}
.m-3 {
    margin: 1rem!important
}
.m-4 {
    margin: 2rem!important
}
.m-5 {
    margin: 4rem!important
}
.m-auto {
    margin: auto!important
}
.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}
.mx-1 {
    margin-right: .25rem!important;
    margin-left: .25rem!important
}
.mx-2 {
    margin-right: .5rem!important;
    margin-left: .5rem!important
}
.mx-3 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}
.mx-4 {
    margin-right: 2rem!important;
    margin-left: 2rem!important
}
.mx-5 {
    margin-right: 4rem!important;
    margin-left: 4rem!important
}
.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}
.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}
.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}
.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}
.my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}
.my-4 {
    margin-top: 2rem!important;
    margin-bottom: 2rem!important
}
.my-5 {
    margin-top: 4rem!important;
    margin-bottom: 4rem!important
}
.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}
.mt-0 {
    margin-top: 0!important
}
.mt-1 {
    margin-top: .25rem!important
}
.mt-2 {
    margin-top: .5rem!important
}
.mt-3 {
    margin-top: 1rem!important
}
.mt-4 {
    margin-top: 2rem!important
}
.mt-5 {
    margin-top: 4rem!important
}
.mt-auto {
    margin-top: auto!important
}
.mr-0 {
    margin-right: 0!important
}
.mr-1 {
    margin-right: .25rem!important
}
.mr-2 {
    margin-right: .5rem!important
}
.mr-3 {
    margin-right: 1rem!important
}
.mr-4 {
    margin-right: 2rem!important
}
.mr-5 {
    margin-right: 4rem!important
}
.mr-auto {
    margin-right: auto!important
}
.mb-0 {
    margin-bottom: 0!important
}
.mb-1 {
    margin-bottom: .25rem!important
}
.mb-2 {
    margin-bottom: .5rem!important
}
.mb-3 {
    margin-bottom: 1rem!important
}
.mb-4 {
    margin-bottom: 2rem!important
}
.mb-5 {
    margin-bottom: 4rem!important
}
.mb-auto {
    margin-bottom: auto!important
}
.ml-0 {
    margin-left: 0!important
}
.ml-1 {
    margin-left: .25rem!important
}
.ml-2 {
    margin-left: .5rem!important
}
.ml-3 {
    margin-left: 1rem!important
}
.ml-4 {
    margin-left: 2rem!important
}
.ml-5 {
    margin-left: 4rem!important
}
.ml-auto {
    margin-left: auto!important
}
.p-1 {
    padding: .25rem!important
}
.p-2 {
    padding: .5rem!important
}
.p-3 {
    padding: 1rem!important
}
.p-4 {
    padding: 2rem!important
}
.p-5 {
    padding: 4rem!important
}
.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}
.px-1 {
    padding-right: .25rem!important;
    padding-left: .25rem!important
}
.px-2 {
    padding-right: .5rem!important;
    padding-left: .5rem!important
}
.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}
.px-4 {
    padding-right: 2rem!important;
    padding-left: 2rem!important
}
.px-5 {
    padding-right: 4rem!important;
    padding-left: 4rem!important
}
.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}
.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}
.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}
.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}
.py-4 {
    padding-top: 2rem!important;
    padding-bottom: 2rem!important
}
.py-5 {
    padding-top: 4rem!important;
    padding-bottom: 4rem!important
}
.pt-0 {
    padding-top: 0!important
}
.pt-1 {
    padding-top: .25rem!important
}
.pt-2 {
    padding-top: .5rem!important
}
.pt-3 {
    padding-top: 1rem!important
}
.pt-4 {
    padding-top: 2rem!important
}
.pt-5 {
    padding-top: 4rem!important
}
.pr-0 {
    padding-right: 0!important
}
.pr-1 {
    padding-right: .25rem!important
}
.pr-2 {
    padding-right: .5rem!important
}
.pr-3 {
    padding-right: 1rem!important
}
.pr-4 {
    padding-right: 2rem!important
}
.pr-5 {
    padding-right: 4rem!important
}
.pb-0 {
    padding-bottom: 0!important
}
.pb-1 {
    padding-bottom: .25rem!important
}
.pb-2 {
    padding-bottom: .5rem!important
}
.pb-3 {
    padding-bottom: 1rem!important
}
.pb-4 {
    padding-bottom: 2rem!important
}
.pb-5 {
    padding-bottom: 4rem!important
}
.pl-0 {
    padding-left: 0!important
}
.pl-1 {
    padding-left: .25rem!important
}
.pl-2 {
    padding-left: .5rem!important
}
.pl-3 {
    padding-left: 1rem!important
}
.pl-4 {
    padding-left: 2rem!important
}
.pl-5 {
    padding-left: 4rem!important
}
.text-left {
    text-align: left!important
}
.text-right {
    text-align: right!important
}
.text-center {
    text-align: center!important
}
.text-strike {
    text-decoration: line-through!important
}
.text-italic {
    font-style: italic!important
}
.text-decoration-none {
    text-decoration: none!important
}
.text-decoration-underline {
    text-decoration: underline!important
}
.text-decoration-line-through {
    text-decoration: line-through!important
}
.text-lowercase {
    text-transform: lowercase!important
}
.text-uppercase {
    text-transform: uppercase!important
}
.text-capitalize {
    text-transform: capitalize!important
}
.text-wrap {
    white-space: normal!important
}
.text-nowrap {
    white-space: nowrap!important
}
.text-400 {
    font-weight: 400!important
}
.text-500 {
    font-weight: 500!important
}
.text-600 {
    font-weight: 600!important
}
.text-700 {
    font-weight: 700!important
}
.text-900 {
    font-weight: 900!important
}
.cursor-pointer {
    cursor:pointer
}
.no-shadow,.shadow-none {
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    width: 100%;
    /*padding-right: 1rem;
    padding-left: 1rem;*/
    margin-right: auto;
    margin-left: auto; 
}
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px; } }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px; } }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px; } }
   @media (min-width: 1180px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1100px; } }
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1240px; } }
   
  @media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      max-width: 1320px; } }

 .row {
    --tblr-gutter-x: 1rem;
    --tblr-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--tblr-gutter-y));
    margin-right: calc(-0.5 * var(--tblr-gutter-x));
    margin-left: calc(-0.5 * var(--tblr-gutter-x));
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--tblr-gutter-x) * 0.5);
    padding-left: calc(var(--tblr-gutter-x) * 0.5);
    margin-top: var(--tblr-gutter-y);
  }
  
  .col {
    flex: 1 0 0%;
  }
  
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .page {
    background: #faf8ff;
    color: #1c1c1c
 }
 .no-white-space {
    white-space: unset !important;
 }
//////////// Custom CSS \\\\\\\\\
.horizontal-seperator {
    height: 1px;
    background-color: #eee;
    margin: .75rem 0;
}
/*########## Hover Zoom ##########*/
.hover-zoom {
    .item, .product-item {
        .item-image {
            overflow: hidden;
        }
        img {
            transition: all .3s ease-out; 
        }
        .item-link:hover img {
            transform: scale(1.1);
            -webkit-transition: all .3s ease-in; 
            transition: all .3s ease-in; 
        }
    }
}
/*########## Hover Zoom ##########*/
/*########## Custom CHECKBOX & RADIO ##########*/
label.custom-checkbox, label.custom-radio {
    //display: block;
    width: 95%;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 20px;
    color: #282c3f;
    position: relative;
    font-size: 14px;
    margin: 10px 0 10px;
}
.custom-checkbox input, .custom-radio input {
    margin: 0 16px 0 0;
    visibility: hidden;
}
.custom-checkbox input:checked~.checkbox-indicator {
    border: none;
    background-color: #fff;
    background-color: #000;
}
.checkbox-indicator {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    //top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #c3c2c9;
    background: #fff;
    border-radius: 2px;
}
.checkbox-indicator:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 1;
    width: 8px;
    height: 5px;
    border-color: #fff;
    border-style: none none solid solid;
    border-width: 2px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.radio-indicator {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 2px;
    left: 0;
}
.radio-indicator:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 16px;
    height: 16px;
    border: 1px solid #c3c2c9;
    border-radius: 50%;
    visibility: visible;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    text-align: center;
}

.custom-radio input:checked~.radio-indicator:before {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    border-color: #000;
    border-color: #000;
    visibility: visible;
    background-color: #000;
    background-color: #000;
    -webkit-box-shadow: inset 0 0 0 3px #fff;
    box-shadow: inset 0 0 0 3px #fff;
}
/*########## Custom CHECKBOX & RADIO ##########*/

/*********************************/
 /*///////// ngBootstrap Dropdown \\\\\\\\*/
 .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    /*border: 1px solid rgb(0 0 0 / 8%);*/
    position: static;
    width: 100%;
    grid-gap: 0.1rem;
    grid-gap: 0.1rem;
    gap: 0.1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border-radius: 15px;
    overflow: hidden;
    max-height: 256px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}
.dropdown-menu.show {
    display: grid;
}
.dropdown-item {
    width: 100%;
    padding: 0.5rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: 0.25rem;
    font-size: 14px;
    line-height: 25px;
    vertical-align: middle;
}
.dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    background-color: #cee2ff;
}
.dropdown-group {
    border-radius: 4px;
    border: 1px solid #d5d5d5;
    background: #ffffff;
    font-size: 14px;
    padding: 0 15px;
    color: #424242;
    font-weight: 400;
}
.dropdown-toggle input {
    height: 38px;
    border:0;
}
.dropdown-arrow {
    width: 19px;
    height: 19px;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s, 
                -webkit-transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.dropdown-arrow span {
    display: flex;
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 5px solid #979797;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    pointer-events: none;
}
.dropdown-toggle input::placeholder {
    color: #d3d3d3;
    font-weight: 300;
} 
.dropdown-toggle input:-ms-input-placeholder {
    color: #d3d3d3;    
    font-weight: 300;
}
.dropdown-toggle input::-ms-input-placeholder {
    color: #d3d3d3;    
    font-weight: 300;
}
.dropdown-item svg {
    width: 18px;
}
.dropdown-item span {
    padding-left: 10px;
}

////////// Check Box \\\\\\\\\\
.checkbox {
    --background: #fff;
    --border: #D1D6EE;
    --border-hover: #BBC1E1;
    --border-active: #dc143c;
    --tick: #fff;
    position: relative;
    cursor: pointer;
    input,
    svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        display: block;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        outline: none;
        background: var(--background);
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        border-radius: 3px;
        transition: box-shadow .3s;
        box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
        &:hover {
            --s: 2px;
            --b: var(--border-hover);
        }
        &:checked {
            --b: var(--border-active);
        }
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #000;
        margin-left: 9px;
        white-space: nowrap;
        line-height: 17px;
    }
    svg {
        pointer-events: none;
        fill: none;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--stroke, var(--border-active));
        position: absolute;
        top: 0px;
        top: 1px;
    //top: 1px;
        left: 0px;
        width: 16px;
        height: 16px;
        transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
        input {
            &:checked {
                --s: 2px;
                transition-delay: .4s;
                & + svg {
                    --a: 16.1 86.12;
                    --o: 102.22;
                }
            }
        }
        svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray .6s, stroke-dashoffset .6s;
        }
    }
    &.bounce {
        --stroke: var(--tick);
        input {
            &:checked {
                --s: 11px;
                & + svg {
                    animation: bounce .4s linear forwards .2s;
                }
            }
        }
        svg {
            --scale: 0;
        }
    }
}

@keyframes bounce {
    50% {
        transform: scale(1.2);
    }
    75% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}
/////// Radio Box \\\\\\\\\
.switch-field {
	display: flex;
	//margin-bottom: 36px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #a5dc86;
	box-shadow: none;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

.sub-residential-details.area, .sub-residential-details.budget, .search-page {
    .ngx-slider-limit {
        display: none !important;
    }
    .ngx-slider .ngx-slider-pointer {
        background-color: #E4002A !important;
        width: 18px;
        height: 18px;
        top: -8px;
    }
    .ngx-slider .ngx-slider-pointer:after {
        width: 6px;
        height: 6px;
        top: 6px;
        left: 6px;
    }
    .ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
        background-color: #fff;
    }
    
    .ngx-slider .ngx-slider-selection {
        background: #ff9d9d;
    }
    span.ngx-slider-span.ngx-slider-bubble {
        font-size: 11px;
        font-weight: 400;
    }
    .ngx-slider {
        margin: 25px 0 10px;
    }
}

/*########### Home Header #########*/
app-header.sticky ion-header.homepage-header {
    position: fixed;
    position: sticky;
    top: 0;
    width: 100%;
    background: #e4002b;
    z-index: 999;
}
app-header-inner.show ion-header.homepage-header {
    position: fixed;
    position: sticky;
    top: 0;
    width: 100%;
    background: #e4002b;
    z-index: 999;
}

/*########## Modal ##########*/
.fade {
    transition: opacity 0.15s linear;
}
.fade:not(.show) {
    opacity: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    //background-color: #000;
    background-color: rgb(192 192 192);
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.8;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.d-block {
    display: block !important;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}
@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
.modal.show .modal-dialog {
    transform: none;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    //border: 1px solid rgba(0, 0, 0, 0.2);
    //border-radius: 0.3rem;
    outline: 0;
}
.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0 !important;
}
/*########## Modal ##########*/
/*########## Off Canvas ##########*/
.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    //background-color: #000;
    background-color: #e6e6e6;
}
.offcanvas-backdrop.fade {
    opacity: 0;
}
.offcanvas-backdrop.show {
    opacity: .7;
}
.offcanvas {
    position: fixed;
    overflow-y: auto;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;
}
.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    //border-left: 1px solid rgba(0,0,0,.2);
    transform: translate(100%);
}
.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    //border-left: 1px solid rgba(0,0,0,.2);
    transform: translate(-100%);
}
.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: fit-content;
    max-height: 100%;
    transform: translateY(-100%);
}

.offcanvas.show {
    transform: none;
}
/*########## Off Canvas ##########*/
/*########## collapse & Accordion ##########*/
.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}
.collapse:not(.show) {
    display: none;
}
.accordion-item {
    margin-bottom: 20px;
    .accordion-button {
        display: flex ;
        text-align: left;
        padding: 5px 0;
        letter-spacing: 0.5px;
        font-weight: 600;
        position: relative;
        padding-left: 25px;
    }
    .accordion-button.collapsed:after {
        display: block;
        position: absolute;
        margin-top: 5px;
        left: 0;
        transform: translateY(-50%) rotate(45deg);
        transition: transform 0.3s;
        border-right: 1.5px solid #666666;
        border-bottom: 1.5px solid #666666;
        width: 9px;
        height: 9px;
        content: "";
    }
    .accordion-button:after {
        display: block;
        position: absolute;
        margin-top: 6px;
        left: 0;
        transition: transform 0.3s;
        border-right: 1.5px solid #666666;
        border-bottom: 1.5px solid #666666;
        width: 9px;
        height: 9px;
        content: "";
        margin-top: 6px;
        transform: translateY(-10%) rotate(225deg);
    }
}
.accordion-item:last-child {
    margin-bottom: 0;
}
.accordion-body {
    padding: 5px 0;
    font-size: 15px;
    letter-spacing: .25px;
}

/*########## collapse & Accordion ##########*/

 .carousel {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}
ngb-carousel .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}
 .carousel,.carousel-inner {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    width: 100%;
    overflow: hidden
}

.carousel-inner:after,.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active,ngbd-api-docs,ngbd-api-docs-class,ngbd-api-docs-config {
    display: block
}

.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start) {
    transform: translate(100%)
}

.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end) {
    transform: translate(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000
}

.carousel-dark .carousel-caption {
    color: #000
}
ngb-carousel .picsum-img-wrapper {
    position: relative;
    height: 100%;
    //padding-top: 55%
}

/*ngb-carousel .picsum-img-wrapper>img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}*/
ngb-carousel .carousel-item img {
    width: 100%
}



/*########## Breadcrumbs ##########*/
.breadcrumbs {
    padding: 15px 0px;
    font-size: 11.5px;
    color: #8993A4;
    span.bread-sep {
        padding: 0 5px;
        font-size: 16px;
        line-height: 1;
        margin-top: -2px;
    }
}
/*########## Breadcrumbs ##########*/
/*########## Toggle Switch ##########*/
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 18px;
    input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s cubic-bezier(0,1,0.5,1);
        border-radius: 4px;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        width: 22px;
        height: 22px;
        left: 0;
        bottom: -2px;
        border: 1px solid #eee;
        box-shadow: 0 1px 4px 0 rgb(48 48 48 / 37%);
        background-color: white;
        transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
        border-radius: 3px;
      }
      
      input:checked + .slider {
        background-color: #52c944;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
        box-shadow: 0 1px 4px 0 rgb(228 0 43 / 37%);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      
      input:checked + .slider.red {
        background-color: #e4002b;
      }
      

      
      input:checked + .slider.red {
        background-color: #e4002b;
      }
      
      #round {
        border-radius: 34px;
      }
      
      #round:before {
        border-radius: 50%;
      }
      
      input:checked + .slider.red {
        background-color: #e4002b;
      }
  }
  
 

/*########## Toggle Switch ##########*/
/*########## Floating Label & Select ##########*/
.single-input {
    position: relative;
    width: 100%;
}
.floating-label {
    pointer-events: none;
    position: absolute;
    top: 11px;
    background: #fff;
    padding: 0 5px;
    left: 11px;
    z-index: 1;
    font-size: 13px;
    color: #94969f;
    transition-property: top;
    transition-duration: .1s;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
}
.floating-input, .floating-select {
    position: relative;
    width: 100%;
    font-size: 14px;
    padding: 0 15px;
    height: 37px;
    border: 1px solid #d4d5d9;
    background-color: transparent;
    //font-weight: 500;
    border-radius: 3px;
    line-height: 14px;
    z-index: 1;
        &:focus{
        outline:none;
        ~ .floating-label{
            top:-8px;
            font-size:13px;
        }
    }
}

select.floating-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.floating-select::-ms-expand {
    display: none;
}

.floating-input:not(:placeholder-shown) ~ .floating-label {
    top:-8px;
    font-size:13px;
}
.floating-select:not([value=""]):valid ~ .floating-label {
    top:-8px;
    font-size:13px;
}
.floating-select[value=""]:focus ~ .floating-label {
    top:11px;
    font-size:13px;
}
.floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}
.single-input.mobile-input {
    .floating-input {
        padding-left: 45px;
    }
    .floating-label {
        z-index: 2;
    }
}
.single-input.mobile-input:before {
    content: "+91-";
    left: 15px;
    font-size: 14px;
    z-index: 2;
    position: absolute;
    color: #000000;
    border-radius: 3px 0 0 3px;
}
.single-input.rupee-input {
    .floating-input {
        padding-left: 15px !important;
    }
    .floating-label {
        z-index: 2;
    }
}
.single-input.rupee-input:before {
    content: "₹";
    left: 0;
    margin-top: -1px;
    font-size: 14px;
    font-weight: 500;
    z-index: 2;
    position: absolute;
    color: #000000;
    border-radius: 3px 0 0 3px;
}
/*########## Floating Label & Select ##########*/
.modal-close {
    border-radius: 50%;
    //background: rgba(255,255,255,0.7);
    border: 9px solid transparent;
    color: #777;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //background: rgba(0,0,0,0.05);
    //margin-left: auto;
}
.modal-close:hover {
    background: rgba(0,0,0,0.05);
}
.modal-close:before, .modal-close:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background-color: #282828;
    background-color: #777;
    transform-origin: 50% 50%;
    opacity: 1;
    -moz-transition: -moz-transform ease 0.25s;
    -webkit-transition: -webkit-transform ease 0.25s;
    -o-transition: -o-transform ease 0.25s;
    -ms-transition: -ms-transform ease 0.25s;
    transition: transform ease 0.25s;
}
.modal-close:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.modal-close:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.modal-close:hover:after, .modal-close:hover:before {
    background-color: #000;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}


:root {
    --star-size: 24px;
    --star-color: #d7d7d7;
    --star-background: #ffa500;
}

.Stars {
    --percent: calc(var(--rating) / 5 * 100%);

    display: inline-block;
    font-size: var(--star-size);
    font-family: Times; // make sure ★ appears correctly
    line-height: 1;

    &::before {
        content: '★★★★★';
        letter-spacing: 3px;
        background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
  
/*################## Soldout banner animation ##################*/
.sold-out .sold-out-banner {
    opacity: 1;
}
.sold-out-banner {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000c7;
    color: white;
    overflow-x: hidden;
    padding: 9px 0 8px;
    z-index: 1;
    //font-weight: 500;
    .banner-container {
      flex-wrap: nowrap;
      white-space: nowrap;
      min-width: 100%;
      .text-message {
        flex-shrink: 0;
        align-items: center;
        animation: marquee 20s linear infinite;
        p {
            font-size: 10px;
            letter-spacing: 1px;
            padding: 0 15px;
        }
      }
    }
}
@keyframes marquee {
    from {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
}
  


@media only screen and (max-width: 480px) and (min-width: 315px)  {
    .modal-close {
        width: 36px;
        height: 36px;
    }
}