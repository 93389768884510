//@import '../../../assets/css/lsv.scss';
.lsv-box {
    background-color: #fff;
    z-index: 1;
    padding: 20px;
    width: 696px;
    min-height: 424px;
    width: 650px;
    min-height: 430px;
    padding: 50px;
    position: relative;
}
.lsv-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 45px;
    height: 42px;
    cursor: pointer;
    svg {
        width: 26px;
    }
}
.ls-heading {
    font-size: 26px;
    text-transform: uppercase;
}
.ls-sub-heading {
    font-size: 15px;
    color: #7e7e7e;
    margin: 5px 0 0;
    line-height: 1.4;
}
.ls-input {
    width: 100%;
    min-width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.ls-input {
    input {
        width: 100%;
        margin-top: 10px;
        border: 1px solid #e8e8e1;
        height: 52px;
        outline: none;
        padding: 5px 40px 5px 16px;
        font-size: 15px;
        //border-radius: 3px;
    }
    input:focus {
        border: 1px solid #000;
    }
    span.ls-warning {
        display: none;
        margin-left: auto;
        color: rgb(242, 81, 57);
        font-size: 12px;
        padding-top: 5px;
        line-height: 1;
    }
    button.ls-submit {
        width: 100%;
        margin-top: 30px;
        border: 0;
        background-color: #000;
        height: 46px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        border-radius: 2px;
        cursor: pointer;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    button.ls-submit:hover {
        background-color: #292929;
        //background-color: #000;
    }
}
.ls-info {
    text-align: center;
    margin-top: 20px;
    color: #979797;
    font-size: 13px;
    a {
        color: #000; 
    }
}
.ls-seperator {
    //margin-top: 40px;
    font-size: 14px;
    position: relative;
    width: 100%;
    span {
        background: #fff;
        z-index: 1;
        padding: 0 10px;
    }
}
.ls-seperator::before {
    content: "";
    position: absolute;
    background: #dcdcdc;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
}
.ls-social {
    //display: none;
    font-size: 14px;
    margin-top: 20px;
    svg {
        width: 19px;
    }
    span {
        margin-left: 5px;
    }
    a {
        border: 1px solid #ddd;
        padding: 8px 15px;
        border-radius: 3px;
    }
    .google {
        //margin-left: 5px;
    }
    .facebook {
        //margin-right: 5px;
    }
}
.ls-otp {
    span.mobile {
        color: #000;
        font-weight: 600;
        padding-left: 8px;
    }
    .otp-input-box {
        margin-top: 40px;
    }
    .ls-otp-submit {
        width: 100%;
        margin-top: 40px;
        border: 0;
        background-color: #000;
        height: 46px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        border-radius: 2px;
        cursor: pointer;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .ls-submit:hover {
        background-color: #292929;
    }
}

.otp-input-resend {
    font-size: 13px;
    margin-top: 15px;
    span.wait {
        font-weight: 600;
        margin-left: 5px;
    }
    .after-wait {
        //font-weight: 600;
        a {
            color:#2874f0;
            color: #8d8d8d;
            margin: 0 5px;
        }
        span.sep {
            color: #b3b2b2;
            font-size: 13px;
            margin-top: -1px;
        }
    }
}
.login-email {
    padding-top: 30px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
}
.ls-sub-heading a {
    color: #5a5a5a;
    font-weight: 600;
    font-size: 12px;
    margin-left: 10px;
    text-decoration: underline;
}
.forget-pass {
    margin-top: 30px;
    font-size: 13px;
    //font-weight: 500;
    a {
        color: #5e5e5e85;
        margin-top: 3px;
    }
    a:hover {
        color: #424242;
    }
}
.password-sent {
    .password-sent-txt {
        font-size: 16px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 500;
        line-height: 1.4;
        span {
            font-weight: 700;
            color: #000;
        }
    }
    .ls-submit {
        width: 100%;
        margin-top: 24px;
        border: 0;
        background-color: #000;
        height: 46px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        border-radius: 2px;
        cursor: pointer;
        padding: 0;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
    .ls-submit:hover {
        background-color: #292929;
    }
}

.user-registration {
    .single-input {
        width: 100%;
        position: relative;
        margin-top: 10px;
    }
    .floating-label {
        top: 25px;
        font-size:14px;
        }
        .floating-input {
        z-index: 1;
        &:focus{
          outline:none;
          ~ .floating-label{
            top: 1px;
            font-size:14px;
          }
        }
    }
    .floating-input:not(:placeholder-shown) ~ .floating-label {
        top:1px;
        font-size:13px;
    }
    .sh-pass {
        border: 0;
        background: transparent;
        position: absolute;
        right: 5px;
        top: 24px;
        z-index: 2;
        cursor: pointer;
        svg {
            width: 20px;
            color: #8f8f8f;
        }
    } 
    .not-visible svg.close {
        display: none;
    }
    .visible svg.open {
        display: none;
    }  
}

@media screen and (max-width: 480px) {
    .lsv-box {
        width: 100%;
        height: 100vh;
        padding: 20px 25px;
    }
    .ls-sub-heading {
        font-size: 14px;
    }
    .lsv-common {
        width: 100%;
    }
    .ls-input {
        min-width: unset;
        span.ls-warning {
            margin-left: unset;
        }
    }
    .ls-social {
        width: 80%;
        a, .google {
            width: 80%;
        }
        a {
            justify-content: center;
            align-items: center;
        }
    }
    .ls-password, .user-registration {
        width: 100%;
    }
    .ls-heading, .ls-sub-heading {
        justify-content: center;
        align-items: center;
    }
    .forget-pass {
        a {
            text-align: center;
        }
    }

    


}

@media only screen and (max-width: 480px) and (min-width: 315px)  {
    .ls-heading {
        font-size: 22px;
        font-weight: 500;
    }
    .ls-sub-heading {
        text-align: center;
    }
    .ls-input {
        margin-top: 20px;
    }
    .modal-brand {
        position: absolute;
        top: 25px;
        svg {
            height: 15px;
        }
    }
    .lsv-common {
        margin-bottom: 30px;
    }
}
